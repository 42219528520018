var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthStyled', [_vm.assetWithHealth ? _c('AssetHealthHeaderOrganism', {
    attrs: {
      "asset": _vm.assetWithHealth,
      "isLoading": _vm.$apollo.queries.assetIssues.loading
    }
  }) : _vm._e(), _vm.assetWithHealth ? _c('router-view', {
    attrs: {
      "asset": _vm.assetWithHealth
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }